import { datadogLogs } from '@datadog/browser-logs'
import { useQuery, UseQueryResult } from 'react-query'

import { AccessoriesResponse } from '../model/AccessoriesResponse'
import { Service } from '../model/Quote'
import { ShowroomFiltersResponse } from '../model/ShowroomFiltersResponse'
import { ShowroomQuoteApi } from '../model/ShowroomQuote'
import { VoVehicle } from '../model/VoVehicle'
import apiClient from './api'
import useAuth from './auth/useAuth'
import { isValidLocale } from './locale'

export const useAllTranslations = (locale: string) => {
  const validLocale = isValidLocale(locale) ? locale : 'en_IE'
  return useQuery(
    'allTranslations',
    async () => {
      const { data } = await apiClient.getAllTranslations(validLocale)
      return data
    },
    { enabled: false },
  )
}

export const useSavedConfigurations = (locale: string) => {
  return useQuery('quotes', async () => {
    const { data } = await apiClient.getSavedConfigurations(locale)
    return data
  })
}

export const useSavedConfiguration = (
  quoteId: string,
  isSavedQuote: boolean,
  locale: string,
) => {
  return useQuery(
    ['saved-configuration', quoteId],
    async () => {
      const { data } = await apiClient.getSavedConfiguration(quoteId, locale)
      return data
    },
    { enabled: isSavedQuote },
  )
}

export const useQuote = (quote: string, locale: string) => {
  return useQuery(
    ['quote', quote],
    async () => {
      const { data: profile } = await apiClient.getProfile(locale)
      const { data } = profile.user.quoteSubmitted
        ? await apiClient.getSubmittedOrder(quote, locale)
        : await apiClient.getQuote(quote, locale)
      return data
    },
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useProfile = (locale: string) => {
  const { user: userData } = useAuth()
  return useQuery(
    'profile',
    async () => {
      const { data } = await apiClient.getProfile(locale)
      return data
    },
    {
      enabled: false,
      retry: false,
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        const errorData = error?.response?.data
        datadogLogs.logger.error('User Profile Error', {
          name: errorData?.errorCode,
          id: errorData?.trace,
          userData: {
            userName: userData?.username,
          },
        })
      },
    },
  )
}

export const useVehicle = (
  vehicleId: string | number,
  locale: string,
  isEnabled: boolean,
) => {
  return useQuery(
    ['vehicle', vehicleId],
    async () => {
      const { data } = await apiClient.getVehicle(vehicleId, locale)
      return data
    },
    { enabled: isEnabled, retry: 1 },
  )
}

export const useSavedQuote = (
  identifier: string,
  locale: string,
  isEnabled: boolean,
) => {
  return useQuery(
    ['saved-quote', identifier],
    async () => {
      const { data } = await apiClient.getSavedQuote(identifier, locale)
      return data
    },
    { enabled: isEnabled },
  )
}

export const useVehicleConfiguration = (
  vehicleId: string,
  locale: string,
  configuration: string,
  isEnabled: boolean,
) => {
  return useQuery(
    ['vehicle-configuration', vehicleId, locale, configuration],
    async () => {
      const { data } = await apiClient.getVehicleConfiguration(
        vehicleId,
        locale,
        configuration,
      )
      return data
    },
    { enabled: isEnabled },
  )
}

export const useContractOptions = (
  vehicleId: string | number,
  locale: string,
) => {
  return useQuery(
    ['contractOptions', vehicleId],
    async () => {
      const { data } = await apiClient.getContractOptions(locale)
      return data
    },
    { enabled: false },
  )
}

export const useAccessories = (
  vehicleId: string | number,
  locale: string,
): UseQueryResult<AccessoriesResponse, unknown> => {
  return useQuery(
    ['accessories', vehicleId],
    async () => {
      const { data } = await apiClient.getAccessories(locale)
      return data
    },
    { enabled: false },
  )
}

export const useServices = (
  vehicleId: string | number,
  locale: string,
): UseQueryResult<Service[], unknown> => {
  return useQuery(
    ['services', vehicleId],
    async () => {
      const { data } = await apiClient.getServices(locale)
      return data
    },
    { enabled: false },
  )
}

export const useShowroomFilters = (
  locale: string,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): UseQueryResult<ShowroomFiltersResponse, any> => {
  return useQuery(
    'showroom-filters',
    async () => {
      const { data } = await apiClient.getShowroomFilters(locale)
      return data
    },
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useVehicleSpecification = (locale: string, vehicleId: string) => {
  return useQuery(
    ['vehicle-specification', vehicleId],
    async () => {
      const { data } = await apiClient.getVehicleSpecification(
        locale,
        vehicleId,
      )
      return data
    },
    { enabled: false },
  )
}

export const useShowroomQuotes = (
  locale: string,
  mileage?: string,
  duration?: string,
) => {
  return useQuery(
    'showroom-quotes',
    async () => {
      const { data } = await apiClient.getShowroomQuotes(
        locale,
        mileage,
        duration,
      )
      return data
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  )
}

export const useShowroomQuote = (locale: string, quote: ShowroomQuoteApi) => {
  return useQuery(
    ['showroom-quote', quote?.quotationIdentifier],
    async ({ signal }) => {
      const vehicleId =
        process.env.REACT_APP_USE_DATA_SUPPLIER_ID_SHOWROOM === 'true'
          ? quote.vehicle.dataSupplierIdentity
          : quote.vehicle.vehicleId
      const { data } = await apiClient.getShowroomQuote(
        locale,
        vehicleId,
        quote.mileage,
        quote.duration,
        quote.quotePriceParameter,
        quote.quotePrice,
        signal,
      )
      return data
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
}

export const useImaginSwatches = (
  vehicle: VoVehicle,
  exteriorColors: string,
) => {
  return useQuery(
    ['imagin-swatches', vehicle?.make?.code, exteriorColors],
    async () => {
      const { data } = await apiClient.getImaginSwatches(
        vehicle.make.code,
        exteriorColors,
      )
      return data
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
}

export const usePublicQuote = (identifier: string, locale: string) => {
  return useQuery(
    ['public-quote', identifier, locale],
    async () => {
      const { data } = await apiClient.getPublicQuote(identifier, locale)
      return data
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
}

export const usePublicSavedConfiguration = (
  identifier: string,
  locale: string,
) => {
  return useQuery(
    ['public-saved-configuration', identifier, locale],
    async () => {
      const { data } = await apiClient.getPublicSavedConfiguration(
        identifier,
        locale,
      )
      return data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
