import { Configuration } from '@azure/msal-browser'

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_MSAL_CLIENT_ID,
    authority: process.env.REACT_APP_AUTH_MSAL_AUTHORITY,
    knownAuthorities:
      process.env.REACT_APP_AUTH_MSAL_KNOWN_AUTHORITIES?.split(',') || [],
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
}
