import { AppBar, Box, Button } from '@material-ui/core'
import styled from 'styled-components'

import { getStyles, isRebranding } from '../../utils/styles'

const s = () => getStyles().colors.topNav

export const StyledAppBar = styled(AppBar)`
  min-height: 50px;
  box-shadow: none;
  border-bottom: 1px solid ${s().border};
  background: ${s().background};
`

export const LogoSection = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 32px;
`

export const StyledNavMenu = styled.nav`
  display: flex;
  align-items: center;
`

export const StyledNavItem = styled.div`
  svg {
    color: ${s().item.font};
  }
  img {
    color: ${s().item.font};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  min-width: 50px;
  padding: 0 8px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${s().font};
  }
`

export const StyledNavItemText = styled.span`
  padding-left: 5px;
  text-transform: ${() => (isRebranding() ? 'none' : 'uppercase')};
  font-weight: bold;

  @media (max-width: 768px) {
    display: none;
  }
`

export const StyledButton = styled(Button)`
  min-width: 0;
  color: ${s().font};

  span {
    padding: 0;
    margin: 0;
  }
  :focus {
    border: 2px solid #5e9ed6;
  }
`
