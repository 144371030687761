import { datadogLogs } from '@datadog/browser-logs'
import { Route, RouteComponentProps, useRouteMatch } from 'react-router-dom'
import { ReactElement, useEffect, useState } from 'react'

import { ConfiguratorRouteProps } from '../../config/routes'
import { useAppConfig } from '../../context/AppConfigContext'
import { ProfileContextProvider } from '../../context/ProfileContext'
import useLocale from '../../hooks/useLocale'
import { useQueryParams } from '../../hooks/useQueryParams'
import { SpinnerContainer } from '../../pages/showroom/vehicle-list/VehicleList.styled'
import {
  cleanSessionStorage,
  saveParameter,
} from '../../pages/vehicle-configurator/util/sessionStorageUtil'
import apiClient from '../../utils/api'
import useAuth from '../../utils/auth/useAuth'
import { history } from '../../utils/historyStore'
import Spinner from '../spinner/Spinner'
import RebrandingLoader from '../utils/RebrandingLoader'

const setApiParameter = (
  parameterName: string,
  apiClientMethod: (p: string) => void,
) => {
  const ssParam = window.sessionStorage.getItem(parameterName)
  apiClientMethod(ssParam)
}

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  profileNeeded,
  ...rest
}: ConfiguratorRouteProps): ReactElement => {
  const { isAuthenticated, getAccessTokenSilently, isLoading, login, user } =
    useAuth()
  const { auth } = useAppConfig()
  const [isApiTokenSet, setIsApiTokenSet] = useState(false)
  const queryParams = useQueryParams()

  const [isParametersSet, setIsParametersSet] = useState(false)

  const locale = useLocale()

  const isOrderSummaryPage = useRouteMatch(
    '/:locale/order-summary/:quoteParam?',
  )?.isExact

  const caseId = queryParams.get('caseId')
  const customerId = queryParams.get('customerId')
  const policyLevel = queryParams.get('policyLevel')
  const draftPolicy = queryParams.get('draftPolicy') || 'false'
  const submittedQuoteNumber = queryParams.get('submittedQuoteNumber')
  const driverCase = queryParams.get('driverCase') === 'true'

  useEffect(() => {
    if ([!isLoading, !isAuthenticated].every((a) => a)) {
      login({
        caseId,
        customerId,
        policyLevel,
        draftPolicy,
        driverCase,
        submittedQuoteNumber,
      })
    }
  }, [
    isLoading,
    isAuthenticated,
    caseId,
    customerId,
    policyLevel,
    draftPolicy,
    driverCase,
    submittedQuoteNumber,
    login,
  ])

  useEffect(() => {
    const setToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently()
        apiClient.setAuthorizationToken(accessToken)
        setIsApiTokenSet(true)
      } catch (e) {
        datadogLogs.logger.error('Failed to request access token')
      }
    }
    isAuthenticated && setToken()
  }, [isAuthenticated, getAccessTokenSilently, auth?.audience])

  useEffect(() => {
    history.replace({ search: '', state: history.location?.state })

    saveParameter('caseId', caseId, ['caseId'])
    saveParameter('customerId', customerId, ['customerId'])
    saveParameter('policyLevel', policyLevel, ['customerId', 'policyLevel'])
    saveParameter('draftPolicy', policyLevel && customerId && draftPolicy, [
      'customerId',
      'policyLevel',
      'draftPolicy',
    ])
    saveParameter('submittedQuoteNumber', submittedQuoteNumber, [
      'submittedQuoteNumber',
    ])
    driverCase && cleanSessionStorage([])
    setIsParametersSet(true)
  }, [
    caseId,
    customerId,
    draftPolicy,
    policyLevel,
    driverCase,
    submittedQuoteNumber,
  ])

  useEffect(() => {
    if ([user, isParametersSet].every((a) => a)) {
      setApiParameter('caseId', apiClient.setCaseIdParameter)
      setApiParameter('policyLevel', apiClient.setPolicyLevelParameter)
      setApiParameter('customerId', apiClient.setCustomerIdParameter)
      setApiParameter('draftPolicy', apiClient.setDraftPolicyParameter)
      setApiParameter(
        'submittedQuoteNumber',
        apiClient.setSubmittedQuoteNumberParameter,
      )
    }
  }, [user, isParametersSet])

  const authenticationSet = [isAuthenticated, isApiTokenSet].every((a) => a)

  return isLoading ? (
    <SpinnerContainer>
      <Spinner size={20} />
    </SpinnerContainer>
  ) : (
    <Route
      render={(props: RouteComponentProps) =>
        authenticationSet ? (
          <ProfileContextProvider
            locale={locale}
            profileNeeded={profileNeeded}
            isOrderSummaryPage={isOrderSummaryPage}
          >
            <RebrandingLoader />
            <Layout>
              <Component {...props} />
            </Layout>
          </ProfileContextProvider>
        ) : null
      }
      {...rest}
    />
  )
}

export default PrivateRoute
