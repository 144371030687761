import { Container, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import useAuth from '../../utils/auth/useAuth'
import { getStyles } from '../../utils/styles'
import BottomNav from '../navigation/BottomNav'
import TopNav from '../navigation/TopNav'

const ContainerStyled = styled(Container)`
  margin: 1.7rem 0 4.8rem 0;
  padding: 0 15px;
  max-width: 100%;
  background: ${getStyles().colors.configurator.fullScreenBottomNav};
`
const ShowroomContentWrapper = styled.div`
  min-height: calc(100vh - 14.1rem);
`

const FullScreenWithBottomNavLayout: React.FC = ({ children }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const { isAuthenticated } = useAuth()
  return (
    <>
      <TopNav />
      <ContainerStyled
        style={{ marginTop: isAuthenticated && matches ? '3.5rem' : '1.7rem' }}
      >
        <ShowroomContentWrapper>
          <main role="main">{children}</main>
        </ShowroomContentWrapper>
      </ContainerStyled>
      <BottomNav />
    </>
  )
}

export default FullScreenWithBottomNavLayout
