import { User } from '@auth0/auth0-spa-js'

import { AccountInfo } from '@azure/msal-browser'

import { APP_METADATA_KEY } from '../constants/user'
import { UserInfo } from './auth/authContext'
import { getCountryDefaultLocale, isValidLocale } from './locale'

const getUserData = (user: User) => user[APP_METADATA_KEY]

export const getUserLocale = (user: User) => {
  if (!user) {
    return 'en_IE'
  }

  const userData = getUserData(user)
  const userLanguage = userData?.default_locale
  const userCountryCode = userData?.country_code

  return getLocale(userLanguage, userCountryCode)
}

export const getMsalUser = (user: AccountInfo): UserInfo => {
  return {
    username: user.username,
    userLocale: getMsalUserLocaleAndLanguage(user),
  }
}

const getLocale = (userLanguage: string, userCountryCode: string) => {
  if (userLanguage && userCountryCode) {
    const userLocale = `${userLanguage}_${userCountryCode.substring(
      userCountryCode.length - 2,
    )}`
    if (isValidLocale(userLocale)) {
      return userLocale
    }
  }
  if (userCountryCode) {
    const userLocale = getCountryDefaultLocale(
      userCountryCode.substring(userCountryCode.length - 2),
    )
    if (userLocale) {
      return userLocale
    }
  }
  return 'en_IE'
}

const getMsalUserLocaleAndLanguage = (user: AccountInfo): string => {
  if (!user) return 'en_IE'

  const countryCode = user.idTokenClaims?.entity_id as string
  const defaultLocale = user.idTokenClaims?.default_language as string

  return getLocale(defaultLocale, countryCode)
}
