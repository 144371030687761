import { Redirect } from 'react-router-dom'
import { useParams } from 'react-router'
import { ReactElement, useEffect, useState } from 'react'

import apiClientService from '../../utils/api'
import { UserInfo } from '../../utils/auth/authContext'

import useAuth from '../../utils/auth/useAuth'
import { normalizeLocale } from '../../utils/locale'

const Login = (): ReactElement => {
  const { locale } = useParams<{ locale: string }>()

  const { isLoading, isAuthenticated, user } = useAuth()

  const [userLocale, setUserLocale] = useState<string>(null)

  useEffect(() => {
    if (isAuthenticated && user) {
      const currentLocale = getLocale(user, locale)
      apiClientService
        .logout(currentLocale)
        .finally(() => setUserLocale(currentLocale))
    }
  }, [isAuthenticated, user, locale])

  return !isLoading && userLocale && <Redirect to={`/${userLocale}/showroom`} />
}

const getLocale = (user: UserInfo, locale: string) => {
  if (locale) {
    return normalizeLocale(locale)
  } else {
    return user.userLocale
  }
}

export default Login
