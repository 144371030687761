import { Container, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import useAuth from '../../utils/auth/useAuth'
import BottomNav from '../navigation/BottomNav'
import TopNav from '../navigation/TopNav'

const AppContentWrapper = styled.div`
  padding-bottom: 10.4rem;
  padding-top: 6.4rem;
  min-height: calc(100vh - 14.1rem);
`

const MainLayout: React.FC = ({ children }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const { isAuthenticated } = useAuth()
  return (
    <>
      <TopNav />
      <Container
        style={{ marginTop: isAuthenticated && matches ? '3.5rem' : '1.7rem' }}
      >
        <AppContentWrapper>
          <main role="main">{children}</main>
        </AppContentWrapper>
      </Container>
      <BottomNav />
    </>
  )
}

export default MainLayout
