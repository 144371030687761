import { useAuth0 } from '@auth0/auth0-react'

import { useMemo } from 'react'

import { useAppConfig } from '../../context/AppConfigContext'
import { getUserLocale } from '../user'
import { AppState, AuthContext, AuthContextType } from './authContext'

export const Auth0Context: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
    loginWithRedirect,
    logout,
    user,
  } = useAuth0()

  const { auth } = useAppConfig()

  const value = useMemo<AuthContextType>(() => {
    const login = (appState: AppState) => {
      const {
        caseId,
        customerId,
        policyLevel,
        draftPolicy,
        driverCase,
        submittedQuoteNumber,
      } = appState

      return loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
          ...{
            caseId,
            customerId,
            policyLevel,
            draftPolicy,
            driverCase,
            submittedQuoteNumber,
          },
        },
      })
    }

    const getAccessToken = () => {
      return getAccessTokenSilently({
        authorizationParams: { audience: auth?.audience },
      })
    }

    const performLogout = () => {
      logout({
        clientId: auth?.clientId,
        logoutParams: {
          returnTo: `${window.location.origin}?enforceLogin=true`,
        },
      })
    }

    return {
      isAuthenticated,
      user: {
        username: user?.nickname,
        userLocale: getUserLocale(user),
      },
      isLoading,
      getAccessTokenSilently: getAccessToken,
      login,
      logout: performLogout,
    }
  }, [
    auth?.audience,
    auth?.clientId,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user,
  ])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
