import React, { createContext, useMemo } from 'react'

type AppConfigProps = { children: React.ReactNode }

export interface AppConfig {
  features: {
    savedQuoteRedirect: string
  }
  auth: {
    provider: string | null
    domain: string
    clientId: string
    audience: string
  }
}

export const AppConfigContext = createContext<AppConfig>({} as AppConfig)

function AppConfigProvider({ children }: AppConfigProps) {
  const value = useMemo(
    () => ({
      features: {
        savedQuoteRedirect: process.env.REACT_APP_FEATURE_SAVED_QUOTE_REDIRECT,
      },
      auth: {
        provider: process.env.REACT_APP_AUTH_PROVIDER,
        domain: process.env.REACT_APP_AUTH_DOMAIN,
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        audience: process.env.REACT_APP_AUTH_CLIENT_AUDIENCE,
      },
    }),
    [],
  )
  return (
    <AppConfigContext.Provider value={value}>
      {children}
    </AppConfigContext.Provider>
  )
}

function useAppConfig() {
  const context = React.useContext(AppConfigContext)
  if (context === undefined) {
    throw new Error('useAppConfig must be used within a AppConfigProvider')
  }
  return context
}

export { AppConfigProvider, useAppConfig }
