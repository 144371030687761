import { Auth0Provider } from '@auth0/auth0-react'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'

import { useAppConfig } from '../../context/AppConfigContext'
import { setAppState } from '../authAppState'
import { history } from '../historyStore'
import { Auth0Context } from './Auth0Context'
import { AppState } from './authContext'
import { msalConfig } from './msalConfig'
import { MsalContext } from './MsalContext'

const onRedirectCallback = (appState: AppState) => {
  setAppState(appState)
  history.replace(appState?.returnTo || window.location.pathname)
}

export const AuthProviderWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { auth } = useAppConfig()

  if (auth.provider === 'msal') {
    const msalInstance = new PublicClientApplication(msalConfig)
    return (
      <MsalProvider instance={msalInstance}>
        <MsalContext>{children}</MsalContext>
      </MsalProvider>
    )
  } else {
    return (
      <Auth0Provider
        domain={auth?.domain}
        clientId={auth?.clientId}
        onRedirectCallback={onRedirectCallback}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: auth?.audience,
          organization: process.env.REACT_APP_AUTH_ORGANIZATION,
        }}
      >
        <Auth0Context>{children}</Auth0Context>
      </Auth0Provider>
    )
  }
}
