import { createContext } from 'react'

export interface UserInfo {
  username: string
  userLocale: string
}

export interface AppState {
  returnTo?: string
  caseId?: string
  customerId?: string
  policyLevel?: string
  draftPolicy?: string
  driverCase?: boolean
  submittedQuoteNumber?: string
}

export interface AuthContextType {
  isAuthenticated: boolean
  user: UserInfo | null
  isLoading: boolean
  getAccessTokenSilently: () => Promise<string>
  login: (appState: AppState) => Promise<void>
  logout: () => void
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)
