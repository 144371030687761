export const loginRequest = {
  scopes: process.env.REACT_APP_MSAL_SCOPES?.split(',') || [],
  extraQueryParameters: {
    tenant: 'sa-exlp-ayvens',
    country: 'sa',
    salesChannel: 'exlp',
    partner: 'ayvens',
    locale: 'en-GB',
  },
}
