import { AuthenticationResult } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { datadogLogs } from '@datadog/browser-logs'
import React, { useEffect, useMemo, useState } from 'react'

import { setAppState } from '../authAppState'
import { history } from '../historyStore'
import { getMsalUser } from '../user'
import { AppState, AuthContext, UserInfo } from './authContext'
import { loginRequest } from './loginRequest'

export const MsalContext: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { instance } = useMsal()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState<UserInfo | null>(null)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const handleReturnedAppState = (state: string): AppState => {
      try {
        return JSON.parse(state)
      } catch (error) {
        datadogLogs.logger.error('Error passing state parameter', {
          error,
        })
        return null
      }
    }

    const handleRedirect = async (): Promise<boolean> => {
      const response = await instance.handleRedirectPromise()
      if (response) {
        const { account, state } = response
        if (state) {
          const appState = handleReturnedAppState(state)
          setAppState(appState)
          history.replace(appState?.returnTo || window.location.pathname)
        }
        instance.setActiveAccount(account)
        setUser(getMsalUser(account))
        setIsAuthenticated(true)
        setAccessToken(response.accessToken)

        setIsLoading(false)
        return true
      }
      return false
    }
    const initializeAuth = async (): Promise<void> => {
      await instance.initialize()
      const redirectHandled = await handleRedirect()
      if (redirectHandled) {
        return
      }
      const accounts = instance.getAllAccounts()
      if (accounts.length > 0) {
        const activeAccount = accounts[0]
        instance.setActiveAccount(activeAccount)
        setUser(getMsalUser(activeAccount))
        setIsAuthenticated(true)

        try {
          const response: AuthenticationResult =
            await instance.acquireTokenSilent({
              ...loginRequest,
              account: activeAccount,
            })
          setAccessToken(response.accessToken)
        } catch (error) {
          await instance.acquireTokenRedirect(loginRequest)
        }
      }
      setIsLoading(false)
    }

    initializeAuth()
  }, [instance])

  const value = useMemo(() => {
    const loginWithRedirect = async (appState: AppState): Promise<void> => {
      await instance.loginRedirect({
        ...loginRequest,
        state: JSON.stringify(appState),
      })
    }

    const logout = (): void => {
      instance.logoutRedirect()
      setAccessToken(null)
      setUser(null)
      setIsAuthenticated(false)
    }

    return {
      isAuthenticated,
      user,
      isLoading,
      getAccessTokenSilently: () => {
        return Promise.resolve(accessToken)
      },
      login: loginWithRedirect,
      logout,
    }
  }, [isAuthenticated, user, accessToken, isLoading, instance])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
